<template>
  <div class="deposit" :style="{ opacity: istrue ? '1' : '0' }">
    <div class="t1"></div>
    <div class="flex_items flex_center mt30">
      <div class="t2 bgwhite flex_column flex_items">
        <div id="qrcode" ref="qrcode" class="" style="margin-top: 13vw"></div>
        <p class="f13 mt15 bold" style="color: #ff7900" @click="savePic">
          Save QR Code
        </p>
        <div
          class="mt30 f15 c0 f-center pos"
          style="width: 70vw; word-wrap: break-word"
        >
          {{ info.internalAccountAddress }}
          <van-image
            class="abs"
            fit="contain"
            width="22"
            :src="require('@/images/home/copy.png')"
            style="top: 5px"
            @click="copy"
          ></van-image>
          <input
            type="text"
            :value="info.internalAccountAddress"
            readonly="readonly"
            class="address abs"
            style="
              opacity: 0;
              z-index: -1;
              width: 1px;
              position: fixed;
              bottom: -100px;
            "
          />
        </div>
      </div>
    </div>
    <div class="flex_center">
      <div class="flex_column mt30 pb30" style="color: #666666; width: 90vw">
        <p class="f15 bold">Attenion</p>
        <p class="f14 mt20">
          1. Please don’t deposit any other digital assets except PIX into the
          address above, or they may be permanently lost.
        </p>
        <p class="f14 mt20">
          2. Depositing into that address requires confirmations from the entire
          Pixgram Chain network and will arrive after 12 confirmations.
        </p>
        <p class="f14 mt20">
          3. Minimum deposit amount: 1 PIX. Any deposits less than the minimum
          will not be credited or refunded.
        </p>
        <p class="f14 mt20">
          4. This address is the only one you yourself can use, and you can
          recharge multiple times.
        </p>
        <p class="f14 mt20">
          5. Please ensure that your computer and browser are secure, and your
          information is protected from being tampered with or leaked.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { Toast } from "vant";
export default {
  data() {
    return {
      info: {},
      istrue: false,
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#f5f5f5";
    this.$nextTick(function () {
      this.getData();
    });
  },
  methods: {
    getData() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .get(this.HOST + "/userinfo/money", {
          appVersion: "1.0.6",
          account: localStorage.getItem("account"),
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.info = res.data;
            this.$nextTick(function () {
              this.qrcode(res.data.internalAccountAddress);
            });
          } else {
            Toast(res.msg);
          }
        });
    },
    qrcode(aa) {
      let qrcode = new QRCode("qrcode", {
        width: 200,
        height: 200,
        text: aa,
      });
      this.istrue = true;
    },
    savePic() {
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = "qrcode";
      a.click();
      Toast("succeed");
    },
    copy(txt) {
      var copyobject = document.getElementsByClassName("address")[0];
      copyobject.select();
      document.execCommand("Copy");
      Toast("Copied");
    },
  },
};
</script>
<style>
.deposit .t1 {
  width: 100%;
  height: 430px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(180deg, #ff7900 0%, #f5f5f5 100%);
}
.deposit .t2 {
  width: 90vw;
  height: 420px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px;
}
</style>